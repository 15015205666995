import React from 'react';

import * as St from './styles';

const LayoutSignIn: React.FC = ({ children }) => {
  return (
    <St.Container>
      <St.BackgroundBar />
      <St.SimpleContent>
        <main>{children}</main>
      </St.SimpleContent>
    </St.Container>
  );
};

export default LayoutSignIn;
