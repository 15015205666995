import styled from 'styled-components';

const MAX_WIDTH = 1280;

export interface ILayout {
  height?: string;
}

export const Layout = styled.div<ILayout>`
  display: grid;

  height: ${({ height }) => height || '100%'};

  grid-template-columns: 1fr;
  grid-template-rows: min-content auto;
  grid-template-areas:
    'HE'
    'MA';

  header {
    margin: 0 auto;
    grid-area: HE;
    max-width: ${MAX_WIDTH}px;
    width: 100%;
  }

  main {
    width: 100%;
    flex: 1;
    height: max(fit-content, 70%);
    margin: 21px auto;
    grid-area: MA;
    max-width: ${MAX_WIDTH}px;
    background-color: ${({ theme: { colors } }) => colors.tertiary};
    box-shadow: 1px 1px 7px 0 rgba(201, 201, 201, 0.5);
  }
`;
