enum Constants {
  TOKEN_KEY = '@NovaEra:token',
  TOKEN_BEARER = '@NovaEra:tokenBearer',
  USER = '@NovaEra:user',
  XCODE_KEY = '@NovaEra:xCode',
  IS_LOGGED = 'isLogged',
  USER_ID = 'USER_ID',
  USER_ROLE = 'USER_ROLE',
  ROLE_OPERATOR = 'OPR',
  ROLE_RETAGUARDA = 'RET',
  ROLE_MESA_CREDITO = 'MDC',
  ROLE_SUPERVISOR = 'SUP',
  ROLE_ADMIN = 'ADM',
  ROLE_ATENDANCE = 'ATENDENTE',
}

export default Constants;
