export enum UserTypes {
  ATENDENTE = 'ATENDENTE',
  ADMIN = 'ADMIN',
  OPERADOR = 'OPERADOR',
  PARCEIRO = 'PARCEIRO',
  SUPERVISOR = 'SUPERVISOR',
  MESA_CREDITO = 'MESA DE CRÉDITO',
  RETAGUARDA = 'RETAGUARDA',
  CAIXA = 'CXA',
}

export const EnableTypesToAccessApp = ['ADM', 'CXA'];
