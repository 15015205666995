import styled from 'styled-components';
import { components } from '~/assets';

interface IOptions {
  open: boolean;
}

interface ISubTabs extends IOptions {
  quantity: number;
}

export const Container = styled.div<IOptions>`
  flex-flow: column nowrap;
  color: ${({ theme: { colors } }) => colors.primary};
  background-color: ${({ theme: { colors } }) => colors.tertiary};
  box-shadow: 1px 2px 5px 0 rgba(173, 173, 173, 0.5);

  display: ${({ open }) => (open ? 'flex' : 'none')};
`;

export const SubTabs = styled.div<ISubTabs>`
  display: flex;
  flex-flow: column nowrap;

  height: ${({ open, quantity }) => (open ? `${quantity * 50}px` : '0px')};

  overflow: hidden;

  transition: height 0.7s;
`;

export const Option = styled.div`
  cursor: pointer;
  white-space: nowrap;
  padding: 17px 28px;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const SmallOption = styled.div`
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 28px;
  height: 50px;
  font-size: 0.9rem;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 90%;
  background-color: #d7d7d7;
  align-self: center;
`;

export const Trace = styled.div`
  width: 100%;
  height: 5px;
  background-color: ${({ theme: { colors } }) => colors.primary};
`;

export const Arrow = styled(components.ArrowDown)<IOptions>`
  transform: ${({ open }) => (open ? 'rotate(0deg)' : 'rotate(90deg)')};
  transition: transform 0.4s;

  margin-right: 5px;
`;
