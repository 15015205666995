import styled, { css } from 'styled-components';

type Sizes = 'small' | 'medium' | 'large';

export interface ISpinnerProps {
  color?: string;
  size?: Sizes;
}

const SIZES = {
  small: css`
    width: 25px;
    height: 25px;
  `,
  medium: css`
    width: 50px;
    height: 50px;
  `,
  large: css`
    width: 70px;
    height: 70px;
  `,
};

export const Spinner = styled.svg<ISpinnerProps>`
  position: relative;
  animation: rotate 2s linear infinite;
  ${({ size }) => (size ? SIZES[size] : SIZES.small)}

  circle {
    stroke: ${({ theme, color }) => color || theme.colors.primary};
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;
