/* eslint-disable */
import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Layout from '~/components/layout';
import { icons } from '~/assets';

import useApi, { ApiMethod } from '~/hooks/useApi';
import endpoints from '~/services/endpoints';
import Loading from '~/components/loading';
import ContainerFlex from '~/components/containerFlex';
import { currencyMask, creditCardMask } from '~/utils/masks';

import ReportTransactionPayload from '~/services/reportTransactions/payload';
import { ReportTransactionResponse } from '~/services/reportTransactions/response';

import * as St from './styles';
import TableList from '~/components/tableList';
import { IListColumn } from '~/models/listCol';
import { useHistory } from 'react-router-dom';
import { HeaderTransactionHistory } from '~/models/modelTransactionHistory';
import Constants from '~/utils/constants';

const TransactionHistory: React.FC = () => {
  const [transactions, setTransactions] = useState([]);
  const [transactionsYesterday, setTransactionsYesterday] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsList, setItemsList] = useState([]);

  const RequestReport = useApi(endpoints.transaction.report, ApiMethod.POST);
  const userId = localStorage.getItem(Constants.USER_ID);

  useEffect(() => {
    (async () => {
      const getTransacions = async () => {
        const payload: ReportTransactionPayload = {
          dataFinal: moment().format('YYYY-MM-DD'),
          dataInicial: moment().subtract(1, 'days').format('YYYY-MM-DD'),
          usuarioId: userId,
        };

        const response = await RequestReport.callApi(payload);

        if (response.status === 200) {
          const data = response.data as ReportTransactionResponse;

          const requestReportTransactionFiltered = data?.data?.filter(
            (a) =>
              a.data.includes(moment().format('YYYY-MM-DD')) &&
              a.estabelecimento.includes('Transação Digital'),
          );

          const requestReportTransactionFilteredYesterday = data?.data?.filter(
            (a) =>
              a.data.includes(
                moment().subtract(1, 'days').format('YYYY-MM-DD'),
              ) && a.estabelecimento.includes('Transação Digital'),
          );

          const list = [];
          const newList = [];

          let x = 0;

          requestReportTransactionFiltered?.forEach((i) => {
            const item: IListColumn[] = [
              {
                type: 'id',
                value: x++,
                maxChar: 5,
              },
              {
                id: 1,
                border: true,
                title: 'Data da Transação',
                value: i.data ? moment(i.data).format('DD/MM/YYYY HH:mm') : '',
                maxChar: 20,
              },
              {
                id: 2,
                border: true,
                title: 'Nome Estabelecimento',
                value: i.estabelecimento,
                maxChar: 25,
                textAlign: 'center',
              },
              {
                id: 3,
                border: true,
                title: 'Número do Cartão',
                value: creditCardMask(i.numeroCartao).masked,
                maxChar: 20,
                textAlign: 'center',
              },
              {
                id: 4,
                border: true,
                title: 'Parcela',
                value:
                  i.parcela_atual === 1 ? 'À vista' : i.parcela_atual + 'x',
                textAlign: 'center',
              },
              {
                id: 5,
                border: true,
                title: 'Valor',
                value: `R$ ${currencyMask(i.valor)}`,
                textAlign: 'center',
              },
              {
                id: 6,
                border: true,
                title: 'Valor da Compra',
                value: `R$ ${currencyMask(i.valorCompra)}`,
                textAlign: 'center',
              },
              {
                id: 7,
                item: i,
                searchAction: () => goToReceiptTaxYesterday(i.idTransacao),
              },
            ];

            list.push(item);
            setItemsList(list);
          });

          requestReportTransactionFilteredYesterday?.forEach((i) => {
            const newItem: IListColumn[] = [
              {
                type: 'id',
                value: x++,
                maxChar: 5,
              },
              {
                id: 1,
                border: true,
                title: 'Data da Transação',
                value: i.data ? moment(i.data).format('DD/MM/YYYY HH:mm') : '',
                maxChar: 20,
              },
              {
                id: 2,
                border: true,
                title: 'Nome Estabelecimento',
                value: i.estabelecimento,
                maxChar: 25,
                textAlign: 'center',
              },
              {
                id: 3,
                border: true,
                title: 'Número do Cartão',
                value: creditCardMask(i.numeroCartao).masked,
                maxChar: 20,
                textAlign: 'center',
              },
              {
                id: 4,
                border: true,
                title: 'Parcela',
                value:
                  i.parcela_atual === 1 ? 'À vista' : i.parcela_atual + 'x',
                textAlign: 'center',
              },
              {
                id: 5,
                border: true,
                title: 'Valor',
                value: `R$ ${currencyMask(i.valor)}`,
                textAlign: 'center',
              },
              {
                id: 6,
                border: true,
                title: 'Valor da Compra',
                value: `R$ ${currencyMask(i.valorCompra)}`,
                textAlign: 'center',
              },
              {
                id: 7,
                item: i,
                searchAction: () => goToReceiptTaxYesterday(i.idTransacao),
              },
            ];

            newList.push(newItem);
            setItems(newList);
          }),
            setTransactions(list);
          setTransactionsYesterday(newList);
        }
      };

      setIsLoading(true);
      await getTransacions();
      setIsLoading(false);
    })();
  }, []);

  const history = useHistory();

  const goToReceiptTaxYesterday = (id) => {
    history.push('/tax-receipt', { id });
  };

  return (
    <>
      <Loading status={isLoading} />

      <Layout>
        <ContainerFlex padding={16}>
          <St.Results>Transações hoje</St.Results>
          {transactions.length > 0 ? (
            <TableList
              customerHeader={HeaderTransactionHistory}
              list={itemsList}
            />
          ) : (
            'Nenhuma transação encontrada'
          )}
        </ContainerFlex>
        <ContainerFlex padding={16}>
          <St.Results>Transações ontem</St.Results>
          {transactionsYesterday.length > 0 ? (
            <TableList customerHeader={HeaderTransactionHistory} list={items} />
          ) : (
            'Nenhuma transação encontrada'
          )}
        </ContainerFlex>
      </Layout>
    </>
  );
};

export default TransactionHistory;
