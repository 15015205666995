/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
/* eslint-disable */
export interface IDefaultReturn {
  masked: string;
  unmasked: string;
}

const defaultReturn = (masked, unmasked): IDefaultReturn => ({
  masked,
  unmasked,
});

export const currencyMask = (param: string | number) => {
  if (!param) {
    return '0,00';
  }

  let value = '';

  if (typeof param === 'number') {
    param = param.toFixed(2);
  }

  if (String(param).includes('.') || String(param).includes(',')) {
    value = String(param);
  } else {
    value = `${String(param)}.00`;
  }

  value = String(value).replace(/\D/g, '');

  const v = `${(Number(value) / 100).toFixed(2)}`.split('.');

  const m =
    v[0]
      .split('')
      .reverse()
      .join('')
      .match(/.{1,3}/g) || [];

  for (let i = 0; i < m.length; i += 1) {
    m[i] = `${m[i].split('').reverse().join('')}.`;
  }

  const r = m.reverse().join('');

  const masked = `${r.substring(0, r.lastIndexOf('.'))},${v[1]}`;

  return masked;
};

export const creditCardUnmask = (value = ''): string =>
  value.replace('.', '').replace('.', '').replace('.', '').replace('.', '');

export const dateUnmask = (value = ''): string => value.replace(/\D/gim, '');

export const cepUnmask = (value = ''): string => value.replace(/\D/gim, '');

export const cpfUnmask = (value = ''): string => value.replace(/\D/gim, '');

export const currencyUnmasked = (value = ''): number =>
  Number(value.replace('.', '').replace(',', '.'));

export const phoneUnmask = (value = ''): string =>
  value.replace(/\D/gim, '').replace(/\D/g, '');

/* export const cnpjMask = (value = ''): IDefaultReturn => {
  const maskedValue = value
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');

  return defaultReturn(maskedValue, cnpjMask(value));
};
 */
export const cpfMask = (value = ''): IDefaultReturn => {
  const maskedValue = value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');

  return defaultReturn(maskedValue, cpfUnmask(value));
};

// Coloca * no lugar dos numeros centrais mostrando apenas os 3 primeiros e os 2 últimos
export const hiddeCPFNumber = (value = '') => {
  const valueSplited = value.split('');

  return valueSplited.map((el, index) => {
    if (index > 3 && index < 11) {
      if (index === 7) {
        return '.';
      }
      return '*';
    } else return el;
  });
};

export const creditCardMask = (value = ''): IDefaultReturn => {
  const newValue = value.replace(/\D/g, '');
  const sizeValue = value.length;

  if (sizeValue <= 9)
    return defaultReturn(
      newValue.replace(/(\d{4})(\d{1,4})/g, '$1.$2'),
      creditCardUnmask(value),
    );

  if (sizeValue <= 14)
    return defaultReturn(
      newValue.replace(/(\d{4})(\d{4})(\d{1,4})/g, '$1.$2.$3'),
      creditCardUnmask(value),
    );

  return defaultReturn(
    newValue.replace(/(\d{4})(\d{4})(\d{4})(\d{1,4})/g, '$1.$2.$3.$4'),
    creditCardUnmask(value),
  );
};

export const dateMask = (value = ''): IDefaultReturn => {
  const newValue = value.replace(/\D/g, '').replace(/^(\d{2})(\d)/g, '$1/$2');

  if (newValue.length > 4)
    return defaultReturn(
      newValue.replace(/(\d{2})(\d{1,2})/, '$1/$2'),
      dateUnmask(value),
    );
  return defaultReturn(newValue, dateUnmask(value));
};

export const phoneMask = (value = ''): IDefaultReturn => {
  const maskedValue = value
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/g, '($1) $2')
    .replace(/(\d)(\d{4})$/, '$1-$2');

  return defaultReturn(maskedValue, phoneUnmask(value));
};

export const cepMask = (value = ''): IDefaultReturn => {
  const maskedValue = value
    .replace(/\D/g, '')
    .replace(/^([\d]{2})\.*([\d]{3})-*([\d]{3})/, '$1$2-$3');

  return defaultReturn(maskedValue, cepUnmask(value));
};

export const hourMinSecMask = (value = ''): IDefaultReturn => {
  const maskedValue = value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1:$2')
    .replace(/(\d{2})(\d{1,2})/, '$1:$2')
    .replace(/(:\d{2})\d+?$/, '$1');

  return defaultReturn(maskedValue, cepUnmask(value));
};

export const numericMask = (value = ''): string => value.replace(/\D/g, '');

export const hideEmailMask = (value = ''): string => {
  const [prefix] = value.split('@');

  const prefixToHide = prefix.slice(2);

  return value.replace(prefixToHide, '*'.repeat(prefixToHide.length));
};

export const emailMask = (value = ''): string =>
  value
    .replace(/[^a-zA-Z0-9-_.@]/g, '')
    .trim()
    .toLowerCase();

export const nameMask = (value = '') => {
  return value
    .toUpperCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\w\s]/gi, '')
    .replace(/[0-9]/g, '')
    .replace(/ +(?= )/g, '');
};

export const expirationDateMask = (value = '') => {
  const newValue = value.replace(/\D/g, '').replace(/^(\d{2})(\d)/g, '$1/$2');

  return defaultReturn(newValue, dateUnmask(value));
};

export const decimalMask = (value = '') =>
  Number(value.replace('.', '').replace(',', '.'));

export const cnpjMask = (value = '') => {
  return value
    .replace(/\D/g, '') // Remove tudo o que não é dígito
    .replace(/^(\d{2})(\d)/, '$1.$2') // Coloca ponto entre o segundo e o terceiro dígitos
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3') // Coloca ponto entre o quinto e o sexto dígitos
    .replace(/\.(\d{3})(\d)/, '.$1/$2') // Coloca uma barra entre o oitavo e o nono dígitos
    .replace(/(\d{4})(\d)/, '$1-$2'); // Coloca um hífen depois do bloco de quatro dígitos
};
