import { IconType } from 'react-icons/lib';

export interface ITab {
  readonly label: string;
  readonly url?: string;
  readonly onClick?: () => void;
  readonly subTabs?: ITab[];
  readonly icon?: IconType;
}

export const TABS: ITab[] = [
  {
    label: 'Transação Digital',
    url: '/digital-transaction',
  },
  {
    label: 'Histórico de transações',
    url: '/transaction-history',
  },
];
