import React, { createContext, useEffect, useState } from 'react';
import Constants from '~/utils/constants';

import { icons } from '~/assets';
import Dialog from '~/components/dialog';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import { EnableTypesToAccessApp } from '~/models/user';
import { CustomizedError } from '~/utils/errors';

interface AuthState {
  token: string;
  user: object;
}

interface SignInCredentials {
  code: string;
  password: string;
}

export interface IAuthContext {
  user: any;
  signIn(credentials: SignInCredentials);
  signOut: () => void;
  isAuthenticated: () => boolean;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>({} as AuthState);
  const [popUpError, setPopUpError] = useState(false);
  const [popUpErrorMessage, setPopUpErrorMessage] = useState('');

  useEffect(() => {
    getUserDataOnStorage();
  }, []);

  const isAuthenticated = (): boolean => {
    const isAuth = JSON.parse(localStorage.getItem(Constants.IS_LOGGED));
    return isAuth?.isLogged || false;
  };

  const getUserDataOnStorage = (): void => {
    const user = JSON.parse(localStorage.getItem('@NovaEra:user'));
    if (user) setData((prev) => ({ ...prev, user }));
  };

  const AuthLogin = useApi<any>(Endpoints.authentication.login, ApiMethod.POST);

  const signIn = async ({ code, password }) => {
    const payload = { cpf: code, senha: password };
    const response = await AuthLogin.callApi(payload);

    switch (response.status) {
      case 201:
        const { token, novoToken, ...user } = response.data;

        const isUser = user.perfis.some((el) =>
          EnableTypesToAccessApp.some((profileTypes) => profileTypes === el),
        );

        if (!isUser) {
          throw CustomizedError({
            message: 'Você não possui permissão para prosseguir.',
          });
        }

        localStorage.setItem(Constants.TOKEN_KEY, token);

        localStorage.setItem(Constants.TOKEN_BEARER, novoToken);

        localStorage.setItem(Constants.USER, JSON.stringify(user));

        localStorage.setItem(
          Constants.USER_ROLE,
          JSON.stringify(response.data.perfis || []),
        );

        localStorage.setItem(Constants.USER_ID, response.data.id);

        localStorage.setItem(
          Constants.IS_LOGGED,
          JSON.stringify({ isLogged: true }),
        );

        setData((prev) => ({ ...prev, user: response.data }));
        return { status: 201, data: response.data };

      case 401:
        throw CustomizedError({
          message:
            'Ops! Serviço indisponível no momento. Tente novamente mais tarde.',
        });

      default:
        throw CustomizedError({
          message: response.data.mensagem || response.status,
        });
    }
  };

  const signOut = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  return (
    <>
      <Dialog
        icon={icons.exclamation}
        open={popUpError}
        positiveLabel="Entendi"
        positiveAction={() => setPopUpError(false)}
        message={popUpErrorMessage}
      />

      <AuthContext.Provider
        value={{
          user: data.user,
          signIn,
          signOut,
          isAuthenticated,
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};

export { AuthProvider, AuthContext };
