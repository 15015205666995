/* eslint-disable */
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { icons } from '~/assets';
import Button from '../button';
import {
  cnpjMask,
  cpfMask,
  currencyMask,
  hiddeCPFNumber,
} from '../../utils/masks';
import ReactToPrint from 'react-to-print';
import { useRef } from 'react';

import * as St from './styles';

interface ReceiptProps {
  data: any;
}

const Receipt = ({ data }: ReceiptProps) => {
  const { state } = useLocation();

  const componentRef = useRef<HTMLDivElement>(null);
  const goBack = () => history.back();

  return (
    <St.Container>
      <St.Content ref={componentRef}>
        <St.Logo src={icons.newLogo} />
        <St.Title> Comprovante de transação digital</St.Title>
        <St.Date>
          {moment(data?.dataCriacao).format('DD/MM/YYYY HH:mm:ss')}
        </St.Date>
        <St.Row>
          <St.Extract>
            <St.Subtitle>Valor da compra</St.Subtitle>
            <St.Value>
              R$ {''}
              {currencyMask(data?.valor) || currencyMask(data?.valorCompra)}
            </St.Value>
          </St.Extract>
          <St.Extract>
            <St.Subtitle>Parcela</St.Subtitle>
            <St.Data>
              {data?.detalhes?.parcelas === 1
                ? 'Á vista'
                : `${data?.detalhes?.parcelas}x de R$ ${currencyMask(
                    data?.detalhes?.valorParcela,
                  )}`}
            </St.Data>
          </St.Extract>
          <St.Extract>
            <St.Subtitle>Valor total</St.Subtitle>
            <St.Data>R$ {currencyMask(data?.detalhes?.valorTotal)}</St.Data>
          </St.Extract>
        </St.Row>
        <St.Row>
          <St.Text>Pagador</St.Text>
          <St.Extract>
            <St.Subtitle>Cartão</St.Subtitle>
            <St.Data>
              TeuCard com final {data?.detalhes?.cartao?.numero.substr(12)}
            </St.Data>
          </St.Extract>
          <St.Extract>
            <St.Subtitle>CPF/CNPJ</St.Subtitle>
            <St.Data>
              {hiddeCPFNumber(cpfMask(data?.pagador?.cpf).masked) ||
                hiddeCPFNumber(cnpjMask(data?.cnpj))}
            </St.Data>
          </St.Extract>
        </St.Row>
        <St.Text>Recebedor</St.Text>
        <St.Extract>
          <St.Subtitle>Nome</St.Subtitle>
          <St.Data>{data?.recebedor?.nome}</St.Data>
        </St.Extract>
        <St.Extract>
          <St.Subtitle>CNPJ</St.Subtitle>
          <St.Data>
            {cnpjMask(data?.recebedor?.cpfCnpj) || cnpjMask(data?.cnpj)}
          </St.Data>
        </St.Extract>
        <St.Extract>
          <St.Subtitle>Caixa</St.Subtitle>
          <St.Data>{data?.detalhes?.idTerminal || data?.idTerminal}</St.Data>
        </St.Extract>
        <St.Code>
          <St.Extract>
            <St.Subtitle>Código de autenticação</St.Subtitle>
          </St.Extract>
          <St.Extract>
            <St.Data>{data?.idAutorizador || data?.caixaTransacao}</St.Data>
          </St.Extract>
        </St.Code>
      </St.Content>
      <St.Footer>
        <St.Buttons>
          <ReactToPrint
            trigger={() => (
              <Button outlined fitContent>
                Imprimir
              </Button>
            )}
            content={() => componentRef.current}
          />
        </St.Buttons>
        <St.Buttons>
          <Button title="Sair" onClick={goBack}>
            Voltar
          </Button>
        </St.Buttons>
      </St.Footer>
    </St.Container>
  );
};

export default Receipt;
