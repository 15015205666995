/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
import React, { InputHTMLAttributes, useState, useEffect, useRef } from 'react';
import { icons } from '~/assets';
import Bullet from '../bullet';
import ContainerFlex from '../containerFlex';
import * as St from './styles';

export enum MaskTypes {
  CPF = 'cpfMask',
  CEP = 'cepMask',
  DATE = 'dateMask',
  HOUR_MIN_SEC = 'hourMinSecMask',
  PHONE = 'phoneMask',
  CREDIT_CARD = 'creditCardMask',
}

export interface IOption {
  readonly id?: string | number;
  value: string;
}

export type InputProps = {
  name?: string;
  id?: string;
  label?: string;
  labelFor?: string;
  icon?: any;
  bulletColor?: string;
  disabled?: boolean;
  errorMessage?: string;
  error?: string;
  options: IOption[];
  value?: string;
  onChange?: (any) => void;
} & InputHTMLAttributes<HTMLInputElement>;

const Input = ({
  label,
  labelFor = '',
  value,
  errorMessage,
  onChange,
  readOnly,
  options,
  bulletColor,
}: InputProps): JSX.Element => {
  const [isFocused, setIsFocused] = useState(false);
  const selectRef = useRef<HTMLDivElement>();

  useEffect(() => {
    window.addEventListener('click', (e) => {
      if (selectRef.current) {
        if (!selectRef.current?.contains(e.target as Node)) {
          setIsFocused(false);
        }
      }
    });
  }, []);

  const handleSelect = (el: IOption): void => {
    onChange(el.id);
    setIsFocused(false);
  };

  const handleToggleFocus = () => {
    if (!readOnly) setIsFocused((prev) => !prev);
  };

  const valueSelected = options.find((el) => el.id === value);
  return (
    <ContainerFlex row flex="0">
      <St.Container isFocused={isFocused}>
        {!!label && <label htmlFor={labelFor}>{label}</label>}

        <ContainerFlex row space ref={selectRef}>
          <St.Value onClick={handleToggleFocus}>
            {valueSelected?.value}
          </St.Value>
          <St.ContainerOptions isFocused={isFocused}>
            <div>
              {options.map((el) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div className="option" onClick={() => handleSelect(el)}>
                  {el.value}
                </div>
              ))}
            </div>
          </St.ContainerOptions>
          <St.IconRight
            id="eye"
            src={icons.arrowDown}
            onClick={handleToggleFocus}
          />
        </ContainerFlex>
        {errorMessage && <St.ErrorMessage>{errorMessage}</St.ErrorMessage>}
      </St.Container>
      {bulletColor && <Bullet color={bulletColor} margin={8} marginTop={27} />}
    </ContainerFlex>
  );
};

export default Input;
