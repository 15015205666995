import React, { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ITab } from '../tabs/tabs';

import * as St from './styles';

interface IList {
  readonly open: boolean;
  readonly items: ITab[];
  readonly disableTrace?: boolean;
}

const List: React.FC<IList> = ({ open, items, disableTrace }) => {
  const [tabSelected, setTabSelected] = useState(-1);
  const history = useHistory();

  const onClickOpenTab = (index) => {
    if (index !== tabSelected) setTabSelected(index);
    else setTabSelected(-1);
  };

  const onClickSub = (url: string) => history.push(url);

  return (
    <St.Container open={open}>
      {!disableTrace && <St.Trace />}

      {items.map((item, index) => (
        <div
          key={item.label}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <St.Option
            onClick={() => {
              if (item.onClick) item.onClick();
              onClickOpenTab(index);
              onClickSub(items[index].url);
            }}
          >
            {item.subTabs && <St.Arrow open={index === tabSelected} />}
            {item.label}
          </St.Option>

          <St.SubTabs
            open={index === tabSelected}
            quantity={item.subTabs?.length}
          >
            {item.subTabs?.map((el) => (
              <St.SmallOption onClick={el.onClick} key={el.label}>
                {el.icon}
                {el.label}
              </St.SmallOption>
            ))}
          </St.SubTabs>
          {index + 1 < items.length && <St.Divider />}
        </div>
      ))}
    </St.Container>
  );
};

export default List;
