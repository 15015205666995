/* eslint-disable */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as St from './styles';
import Layout from '~/components/layout';
import Receipt from '~/components/receiptList';
import useApi, { ApiMethod } from '~/hooks/useApi';
import endpoints from '~/services/endpoints';
import Loading from '~/components/loading';

const TaxReceipt: React.FC = () => {
  const [getReceipt, setGetReceipt] = useState(null);
  const { state } = useLocation() as any;
  const { id, data } = state;
  const RequestReceipt = useApi(
    endpoints.transaction.receipt.replace('PARAM_ID', id),
    ApiMethod.GET,
  );

  useEffect(() => {
    if (!data) TransactionReceipt();
  }, [data]);

  const TransactionReceipt = async () => {
    const response = await RequestReceipt.callApi();
    if (response.status >= 200) {
      setGetReceipt(response.data);
    }
  };

  return (
    <>
      <Loading status={RequestReceipt.loading} />
      <Layout>
        <St.Container>
          <Receipt data={getReceipt || data} />
        </St.Container>
      </Layout>
    </>
  );
};

export default TaxReceipt;
