import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

export const BackgroundBar = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 118px;
  background-color: ${({ theme: { colors } }) => colors.primary};
`;

export const Content = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-height: 400px;
  margin-top: 48px;

  > main {
    flex: 1;
    margin: 0 11px;
  }
`;

export const SimpleContent = styled.div`
  z-index: 2;
  display: flex;
  width: 80%;
  height: auto;
  max-width: 810px;
  margin: 60px auto 0;
  padding: 48px 0;

  border-radius: 10px;
  background: #ffffff;
  box-shadow: 1px 3px 15px 0 rgba(201, 201, 201, 0.5);

  > main {
    margin: 0 auto;
    width: 448px;
  }
`;
