import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
  body {
    margin:0;
    padding:0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;

  }

  html,body, #root {
    height: 100%;
    width: 100vw;
    max-width:100%; 
    overflow-x: hidden !important;
    position: sticky;
  }
`;
