import styled from 'styled-components';

export interface IContainer {
  show?: boolean;
}

export interface IAlign {
  alignCenter?: boolean;
}

export const Container = styled.div<IContainer>`
  height: 100%;
  width: 100%;

  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  position: fixed;
  overflow: overlay;
  z-index: 999;
  display: ${(p) => (p.show ? 'flex' : 'none')};
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Message = styled.span<IAlign>`
  font-size: 1em;
  font-weight: 700;
  text-align: ${(p) => (p.alignCenter ? 'center' : 'left')};
  color: ${({ theme: { colors } }) => colors.primary};
`;

export const Divider = styled.div<IContainer>`
  height: 1px;
  width: 100%;
  margin-top: 25px;
`;

export const DividerLine = styled.div<IContainer>`
  height: 1px;
  width: 100%;
  margin-top: 35px;
  background-color: rgba(0, 0, 0, 0.1);
`;

export const Icon = styled.img`
  align-self: start;
  height: 40px;
  object-fit: contain;
`;

export const CloseIcon = styled.span`
  cursor: pointer;
  float: right;
`;

export const TitleHeader = styled.span`
  text-align: center;
  flex: 1;
  font-size: 1.1rem;
  font-weight: 700;
  color: ${({ theme: { colors } }) => colors.primary};
`;
