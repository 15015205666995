/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-pascal-case */
import React, { ChangeEvent, useEffect, useState } from 'react';
import ContainerFlex from '~/components/containerFlex';
import ButtonSearch from '../buttonSearch';
import { IListCol } from '../listView/row/col';

import * as St from './styles';

interface IProps {
  readonly customerHeader: any;
  readonly list: any;
  readonly checkbox?: boolean;
  readonly orderBy?: string;
  onSubmitOrder?: Function;
  onSubmitSelectedRows?: Function;
}

interface ICheckbox {
  id?: number;
  isChecked?: boolean;
  data?: any;
}

const TableList: React.FC<IProps> = ({
  customerHeader,
  list,
  checkbox = false,
  onSubmitOrder,
  orderBy,
  onSubmitSelectedRows,
}) => {
  const [selected, setSelected] = useState<ICheckbox[]>([]);
  const [selectedAll, setSelectedAll] = useState<boolean>(false);
  useEffect(() => {
    if (checkbox) {
      const newList = [];
      list.map((item: IListCol) => {
        newList.push({
          id: item[0].value,
          isChecked: false,
          data: item,
        });
      });

      setSelected(newList);
    }
  }, [list]);

  const isSelected = (id: number) => {
    const found = selected.find((item) => item.id === id);
    if (found?.isChecked) {
      return true;
    }
  };
  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    const newChecked = [...selected];

    setSelected(() => {
      newChecked.find((e) => e.id === id).isChecked = e.target.checked;
      return newChecked;
    });
  };
  const handleCheckAll = () => {
    setSelectedAll(!selectedAll);
    setSelected((prev) =>
      prev.map((item) => ({ ...item, isChecked: !selectedAll })),
    );
  };

  const handleSubmitOrder = (column: String) => {
    if (orderBy === 'ASC') {
      onSubmitOrder({
        order: 'DESC',
        column,
      });
    } else {
      onSubmitOrder({
        order: 'ASC',
        column,
      });
    }
  };

  return (
    <ContainerFlex marginBottom={40}>
      <St.Table>
        <St.hederTable>
          <tr>
            <St.hederTitle maxChar={5} marginLeft={5}>
              {checkbox && (
                <St.CheckBox
                  type="checkbox"
                  name="select-all"
                  id="select-all"
                  checked={selectedAll}
                  onChange={() => {
                    handleCheckAll();
                  }}
                />
              )}
            </St.hederTitle>
            {customerHeader.map((item) => (
              <St.hederTitle
                key={item.id}
                maxChar={item.maxChar}
                textAlign={item.textAlign}
                onClick={() => item.column && handleSubmitOrder(item.column)}
              >
                {item.value}
              </St.hederTitle>
            ))}
          </tr>
        </St.hederTable>
        <St.bodyTable>
          {list.map((item) => (
            <St.bodyRow key={item[0].value}>
              {item.map((item) => (
                <>
                  {item.type === 'id' ? (
                    <St.bodyData
                      maxChar={item.maxChar}
                      textAlign={item.textAlign}
                    >
                      {checkbox && (
                        <St.CheckBox
                          key={item.value}
                          type="checkbox"
                          id={item.value}
                          checked={isSelected(item.value)}
                          onChange={(e) => handleCheckbox(e, item.value)}
                        />
                      )}
                    </St.bodyData>
                  ) : item.type === 'icon' ? (
                    <St.bodyData
                      key={item.id}
                      maxChar={item.maxChar}
                      marginLeft={item.marginLeft}
                      textAlign={item.textAlign}
                    >
                      {item.value ? (
                        <>
                          <St.bodyDataValue maxChar={item.maxChar}>
                            {item.value}
                          </St.bodyDataValue>
                          {item.valueColor && (
                            <St.ListRowBullet color={item.valueColor} />
                          )}
                          <St.IconRow src={item.icon} alt="icon" />
                        </>
                      ) : (
                        <>
                          {item.valueColor && (
                            <St.ListRowBullet color={item.valueColor} />
                          )}
                          <St.IconRow src={item.icon} alt="icon" />
                        </>
                      )}
                    </St.bodyData>
                  ) : item.searchAction ? (
                    <St.bodyData
                      key={item.id}
                      maxChar={item.maxChar}
                      marginLeft={item.marginLeft}
                      textAlign={item.textAlign}
                    >
                      <St.ButtonContainer>
                        <ButtonSearch
                          onClick={() => item.searchAction(item.id, item.item)}
                        />
                      </St.ButtonContainer>
                    </St.bodyData>
                  ) : (
                    <St.bodyData
                      key={item.id}
                      maxChar={item.maxChar}
                      marginLeft={item.marginLeft}
                      textAlign={item.textAlign}
                      title={item.value}
                    >
                      {item.value}
                    </St.bodyData>
                  )}
                </>
              ))}
            </St.bodyRow>
          ))}
        </St.bodyTable>
      </St.Table>
    </ContainerFlex>
  );
};

export default TableList;
