/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import SignIn from '~/views/SignIn';
import Home from '~/views/Home';
import DigitalTransaction from '~/views/DigitalTransaction';
import TransactionHistory from '~/views/TransactionHistory';
import TaxReceipt from '~/views/TaxReceipt';

import PrivateRoute from './privateRoute';
import PublicRoute from './publicRoute';

const Routes: React.FC = () => {
  return (
    <>
      <Switch>
        <PublicRoute path="/" exact component={SignIn} />
        <PrivateRoute path="/home" component={Home} />
        <PrivateRoute path="/tax-receipt" component={TaxReceipt} />
        <PrivateRoute
          path="/digital-transaction"
          component={DigitalTransaction}
        />
        <PrivateRoute
          path="/transaction-history"
          component={TransactionHistory}
        />
        <Redirect to="/home" />
      </Switch>
    </>
  );
};

export default Routes;
