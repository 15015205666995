import styled from 'styled-components';
import { lighten, darken } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-weight: 600;

  /* @media (max-width: 700px) {
    display: none;
  } */
`;

export const SubTab = styled.div`
  position: absolute;
  display: none;
  left: 0;
  top: 71px;
  z-index: 10000;
  > div {
    min-width: 349px;
    text-align: left;
  }
`;

export const Tab = styled.div`
  display: flex;
  flex: 1;
  height: 71px;
  min-width: 100px;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme: { colors } }) => colors.primary};
  color: #ffff;
  border-top: 1px solid #ffff;
  position: relative;
  padding: 0 20px;

  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: ${({ theme: { colors } }) =>
      lighten(0.04, colors.primary)};
  }

  &:hover ${SubTab} {
    display: block !important;
  }

  svg {
    margin-left: 10px;
  }
`;

export const Divider = styled.span`
  height: 71px;
  width: 1px;
  flex-shrink: 1;
  display: block;
  background-color: #dedede;
`;

export const WrapperArrow = styled.div`
  width: 20px;
  min-width: 20px;
  flex-shrink: 1;
`;
