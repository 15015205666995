import React from 'react';
import * as St from './styles';

const Spinner: React.FC<St.ISpinnerProps> = ({ color, size }) => {
  return (
    <St.Spinner viewBox="0 0 40 40" color={color} size={size}>
      <circle cx="20" cy="20" r="15" fill="none" strokeWidth="6" />
    </St.Spinner>
  );
};

export default Spinner;
