import React, { memo, useState } from 'react';

import { useHistory } from 'react-router-dom';
import List from '~/components/list';
import { TABS } from './tabs';
import * as St from './styles';
import { components } from '~/assets';

const DefaultHeader: React.FC = () => {
  const history = useHistory();
  const goToURL = (url: string) => history.push(url);

  return (
    <St.Container>
      {TABS.map((tab, index) => (
        <div key={tab.label} style={{ flex: 1, display: 'flex' }}>
          <St.Tab onClick={() => goToURL(tab.url)}>
            {tab.label}
            {tab.subTabs && (
              <St.WrapperArrow>
                <components.ArrowDownWhite />
              </St.WrapperArrow>
            )}
            {tab.subTabs && (
              <St.SubTab>
                <List items={tab.subTabs} open disableTrace />
              </St.SubTab>
            )}
          </St.Tab>
          {index + 1 < TABS.length && <St.Divider />}
        </div>
      ))}
    </St.Container>
  );
};

export default memo(DefaultHeader);
