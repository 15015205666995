const THEMES = {
  colors: {
    primary: '#1E937D',
    secundary: '#7bb6ab',
    tertiary: '#FFFFFF',
    gray: '#828282',
    error: '#ff1a1a',
  },
  fonts: ['Open Sans', 'Open Sans SemiBold'],
  fontSizes: {
    small: '1rem',
    xsmall: '1.2rem',
    medium: '2rem',
    large: '3rem',
  },
};

export default THEMES;
