import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import Button from '../button';
import CardView from '../cartView';
import ContainerFlex from '../containerFlex';

import * as St from './styles';

export interface IDialog {
  open?: boolean;
  icon?: string;
  message?: string;
  negativeLabel?: string;
  negativeAction?: Function;
  positiveLabel?: string;
  positiveAction?: Function;
  widthInPercent?: number;
  closeButton?: boolean;
  title?: string | JSX.Element;
  widthInPixel?: number;
}

const Dialog: React.FC<IDialog> = ({
  children,
  icon,
  open,
  message,
  positiveLabel,
  positiveAction,
  negativeLabel,
  negativeAction,
  widthInPercent,
  widthInPixel,
  title,
  closeButton = false,
}) => {
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (open) setIsOpened(true);
    else setIsOpened((prev) => (prev === true ? false : null));
  }, [open]);

  const close = () => {
    if (negativeAction) negativeAction();
    setIsOpened(false);
  };

  const confirm = () => {
    if (positiveAction) positiveAction();
  };

  return (
    <St.Container show={isOpened}>
      <CardView space widthPixel={widthInPixel} width={widthInPercent}>
        <ContainerFlex center>
          {/* Header */}
          {icon && (
            <ContainerFlex padding={16}>
              <ContainerFlex row align="center">
                <St.TitleHeader>{title}</St.TitleHeader>
              </ContainerFlex>
              <St.Divider />
            </ContainerFlex>
          )}

          {/* Content */}
          <ContainerFlex padding={16}>
            {message && (
              <St.Message alignCenter>
                {message}
                {closeButton ? (
                  <St.CloseIcon onClick={close}>
                    <MdClose />
                  </St.CloseIcon>
                ) : (
                  ''
                )}
              </St.Message>
            )}
            {children}
          </ContainerFlex>

          <St.DividerLine />

          {/* Buttons */}
          <ContainerFlex row spaceAround wrap padding={16}>
            {negativeLabel && (
              <Button fitContent onClick={close} outlined marginBottom={8}>
                <span>{negativeLabel || 'Fechar'}</span>
              </Button>
            )}

            <Button
              fitContent={!!negativeLabel || widthInPercent != null}
              fullWidth={!negativeLabel}
              onClick={confirm}
            >
              <span>{positiveLabel || 'Confirmar'}</span>
            </Button>
          </ContainerFlex>
        </ContainerFlex>
      </CardView>
    </St.Container>
  );
};

export default Dialog;
