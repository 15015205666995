import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import AppProvider from './contexts';
import GlobalStyles from '~/themes/global';
import Themes from '~/themes/themes';

import Routes from './routes';

function App(): JSX.Element {
  return (
    <BrowserRouter>
      <ThemeProvider theme={Themes}>
        <GlobalStyles />
        <AppProvider>
          <Routes />
        </AppProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
