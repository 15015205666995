import React, { ButtonHTMLAttributes } from 'react';
import * as St from './styles';
import Spinner from '~/components/spinner';
import themes from '~/themes/themes';

type ButtonTypes = ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonProps = {
  size?: 'small' | 'medium' | 'large';
  outlined?: boolean;
  fullWidth?: boolean;
  fitContent?: boolean;
  minimal?: boolean;
  active?: boolean;
  icon?: JSX.Element;
  as?: React.ElementType;
  marginBottom?: number;
  marginLeft?: number;
  loading?: boolean;
} & ButtonTypes;

const Button = ({
  children,
  icon,
  size = 'medium',
  fullWidth = false,
  fitContent = false,
  minimal = false,
  type,
  outlined,
  marginBottom,
  marginLeft,
  loading = false,
  ...props
}: ButtonProps): JSX.Element => (
  <St.Container
    type={type}
    size={size}
    fullWidth={fullWidth}
    hasIcon={!!icon}
    minimal={minimal}
    outlined={outlined}
    fitContent={fitContent}
    marginBottom={marginBottom}
    marginLeft={marginLeft}
    {...props}
  >
    {loading ? (
      <Spinner color={outlined ? themes.colors.primary : '#FFF'} />
    ) : (
      <>
        {icon}
        {!!children && <span>{children}</span>}
      </>
    )}
  </St.Container>
);

export default Button;
