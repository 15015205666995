import logoMedium from './icons/logo-medium.png';
import logoLarge from './icons/logo-large.png';
import IconTick from './icons/tick.png';
import warning from './icons/warning.png';
import arrowLeft from './icons/arrow-left.png';
import arrowRight from './icons/arrow-right.png';
import sendButton from './icons/send-button.png';
import blind from './icons/blind.png';
import check from './icons/check.png';
import noBlind from './icons/no-blind.png';
import arrowDown from './icons/arrow-down.png';
import userAvatar from './icons/user-avatar.png';
import document from './icons/document.png';
import card from './icons/card.png';
import inputEdit from './icons/input_edit.png';
import exclamation from './icons/exclamation.png';
import confirmation from './icons/confirmation.png';
import tableDocumentFront from './icons/table_document_front.png';
import tableDocumentBack from './icons/table_document_back.png';
import printer from './icons/printer.png';
import contract from './icons/contract.png';
import searchIcon from './icons/search.png';
import fileDelete from './icons/file-delete.png';
import filesIcon from './icons/files.png';
import newLogo from './icons/new-logo.png';
import print from './icons/printIcon.png';

import { ReactComponent as ArrowDown } from './svg/arrow-down.svg';
import { ReactComponent as ArrowDownWhite } from './svg/arrow-down-white.svg';
import { ReactComponent as Menu } from './svg/menu.svg';
import { ReactComponent as Close } from './svg/close.svg';
import { ReactComponent as Warning } from './svg/warning.svg';
import { ReactComponent as Search } from './svg/search.svg';
import { ReactComponent as Print } from './svg/printIcon.svg';

export const icons = {
  card,
  inputEdit,
  exclamation,
  confirmation,
  tableDocumentFront,
  tableDocumentBack,
  document,
  printer,
  fileDelete,
  searchIcon,
  filesIcon,
  logoMedium,
  logoLarge,
  IconTick,
  warning,
  arrowLeft,
  arrowRight,
  check,
  sendButton,
  blind,
  noBlind,
  arrowDown,
  userAvatar,
  contract,
  newLogo,
  print,
};

export const components = {
  ArrowDown,
  ArrowDownWhite,
  Menu,
  Close,
  Warning,
  Search,
  Print,
};
