/* eslint-disable react/destructuring-assignment */
import React, { InputHTMLAttributes, useState } from 'react';
import { icons } from '~/assets';
import Bullet from '../bullet';
import ContainerFlex from '../containerFlex';
// import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import * as Masks from '~/utils/masks';
import * as St from './styles';

export enum MaskTypes {
  CPF = 'cpfMask',
  CEP = 'cepMask',
  DATE = 'dateMask',
  HOUR_MIN_SEC = 'hourMinSecMask',
  PHONE = 'phoneMask',
  CREDIT_CARD = 'creditCardMask',
  NUMERIC = 'numericMask',
  CURRENCY = 'currencyMask',
  EMAIL = 'emailMask',
}

export type InputProps = {
  bulletColor?: string;
  name?: string;
  id?: string;
  label?: string;
  labelFor?: string;
  icon?: any;
  initialValue?: string;
  disabled?: boolean;
  errorMessage?: string;
  error?: string;
  edit?: boolean;
  mask?: MaskTypes;
  value?: string;
  placeholder?: string;
  blind?: boolean;
  defaultValue?: string | number;
  onChange?: (e: any) => void;
} & InputHTMLAttributes<HTMLInputElement>;

const Input = ({
  label,
  labelFor = '',
  name,
  placeholder,
  disabled = false,
  value,
  errorMessage,
  onChange = (e) => null,
  edit,
  mask,
  blind,
  bulletColor = null,
  defaultValue,
  ...props
}: InputProps): JSX.Element => {
  const [isFocused, setIsFocused] = useState(false);
  const [isBlinded, setIsBlinded] = useState(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    if (mask && value !== '') {
      const valueMasked = Masks[mask](value as never);

      return onChange({
        target: {
          value: valueMasked,
          name,
        },
      });
    }

    return onChange(e);
  };

  if (bulletColor) {
    return (
      <ContainerFlex row center>
        <St.Container isFocused={isFocused} error={!!errorMessage}>
          {!!label && <label htmlFor={labelFor}>{label}</label>}

          <ContainerFlex width={100} row space>
            <input
              type="text"
              onChange={handleChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              disabled={disabled}
              value={value}
              name={name}
              placeholder={placeholder}
              defaultValue={defaultValue}
              {...props}
            />
            {edit && <St.IconRight src={icons.inputEdit} />}
          </ContainerFlex>

          {errorMessage && <St.ErrorMessage>{errorMessage}</St.ErrorMessage>}
        </St.Container>

        {bulletColor && (
          <Bullet
            color={bulletColor}
            margin={8}
            marginTop={14}
            marginBottom={22}
          />
        )}
      </ContainerFlex>
    );
  }

  return (
    <St.Container isFocused={isFocused} error={!!errorMessage}>
      {!!label && <label htmlFor={labelFor}>{label}</label>}

      <ContainerFlex width={100} row space>
        <input
          type={blind && isBlinded ? 'password' : 'text'}
          onChange={handleChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          disabled={disabled}
          value={value}
          name={name}
          placeholder={placeholder}
          {...props}
        />
        {edit && <St.IconRight src={icons.inputEdit} />}
        {blind && (
          <St.IconRight
            id="eye"
            src={isBlinded ? icons.noBlind : icons.blind}
            onClick={() => setIsBlinded((prev) => !prev)}
          />
        )}
      </ContainerFlex>

      {errorMessage && <St.ErrorMessage>{errorMessage}</St.ErrorMessage>}
    </St.Container>
  );
};

export default Input;
