import styled, { css, DefaultTheme } from 'styled-components';
import { darken } from 'polished';

import { ButtonProps } from '.';

type WrapperProps = {
  hasIcon: boolean;
} & Pick<
  ButtonProps,
  | 'size'
  | 'fullWidth'
  | 'minimal'
  | 'outlined'
  | 'fitContent'
  | 'marginBottom'
  | 'marginLeft'
>;

const wrapperModifiers = {
  small: (theme: DefaultTheme) => css`
    height: 2rem;
    font-size: 14px;
    padding-right: 30px;
    padding-left: 30px;
  `,
  medium: (theme: DefaultTheme) => css`
    height: 3rem;
    font-size: 16px;
    padding: 10px;
    padding-right: 50px;
    padding-left: 50px;
  `,
  large: (theme: DefaultTheme) => css`
    display: flex !important;
    height: 3rem;
    font-size: 14px;
    padding: 10px;
    padding-right: 50px;
    padding-left: 50px;
  `,
  fullWidth: () => css`
    width: 100%;
  `,
  fitContent: () => css`
    width: fit-content;
  `,
  withIcon: (theme: DefaultTheme) => css`
    svg {
      width: 1.5rem;
      & + span {
        margin-left: 10px;
      }
    }
  `,
  outlined: (theme: DefaultTheme) => css`
    background: #fff;
    color: ${theme.colors.primary};
    border: 1px solid ${theme.colors.primary};
    /* &:hover {
      color: ${darken(0.1, theme.colors.primary)};
    } */
  `,
  minimal: (theme: DefaultTheme) => css`
    background: none;
    color: ${theme.colors.primary};
    &:hover {
      color: ${darken(0.1, theme.colors.primary)};
    }
  `,
};

export const Container = styled.button<WrapperProps>`
  ${({
    theme,
    size,
    fullWidth,
    hasIcon,
    minimal,
    outlined,
    fitContent,
    marginBottom,
    marginLeft,
  }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: ${theme.colors.primary};
    color: ${theme.colors.tertiary};
    border: 0;
    height: 48px;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 600;
    padding: 10px;
    text-decoration: none;
    border-radius: 10px;

    margin-left: ${marginLeft ? `${marginLeft}px` : '0px'};
    margin-bottom: ${marginBottom ? `${marginBottom}px` : '0px'};
    ${!!size && wrapperModifiers[size](theme)};
    ${!!fullWidth && wrapperModifiers.fullWidth()};
    ${!!fitContent && !fullWidth && wrapperModifiers.fitContent()};
    ${!!hasIcon && wrapperModifiers.withIcon(theme)};
    ${!!minimal && wrapperModifiers.minimal(theme)};
    ${!!outlined && wrapperModifiers.outlined(theme)};

    &:disabled {
      background: ${theme.colors.secundary};
      ${!!outlined && wrapperModifiers.outlined(theme)};
      cursor: not-allowed;
    }
  `}
`;
