import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  height: 20px;
  width: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    background-color: ${darken(0.045, '#ffffff')};
  }

  svg {
    width: 24px;
  }
`;
