import styled from 'styled-components';

export const Container = styled.div`
  text-align: left;
  margin-top: 30px;
  width: 25%;
`;

export const Content = styled.div`
  align-items: center;
  margin-bottom: 25px;
  @media print {
    padding: 50px 100px 100px 100px;
  }
`;

export const Row = styled.div`
  border-bottom: 1px solid gray;
  padding: 3px;
  margin-bottom: 15px;
`;

export const Code = styled.div`
  margin-top: 20px;
`;

export const Logo = styled.img`
  height: 25px;
  width: 75px;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #424242;
`;

export const Text = styled.div`
  font-size: 14px;
  color: #424242;
  font-weight: ;
  margin-bottom: 5px;
`;

export const Date = styled.text`
  text-align: left;
  font-size: 13px;
  color: #9e9e9e;
  font-weight: 150;
`;

export const Value = styled.div`
  font-size: 20px;
  text-align: right;
  color: #424242;
`;

export const Extract = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

export const Subtitle = styled.text`
  font-size: 14px;
  color: #424242;
  padding: 5px;
`;

export const Data = styled.div`
  font-size: 14px;
  text-align: right;
  color: #424242;
  padding: 5px;
`;

export const Footer = styled.div`
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const Printable = styled.div`
  flex: 1;
`;
