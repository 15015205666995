import React from 'react';

import DefaultHeader from '~/components/defaultHeader';
import Tabs from '~/components/tabs';

import * as St from './styles';

const Layout: React.FC<St.ILayout> = ({ children, height }) => {
  return (
    <St.Layout height={height}>
      <header>
        <DefaultHeader />
        <Tabs />
      </header>

      <main>{children}</main>
    </St.Layout>
  );
};

export default Layout;
