const Authentication = {
  login: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/auth/login`,
  // passwordValidation: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/noauth/validar-senha`,
  passwordValidation: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/noauth/validar-senha-usuario`,
  userByIdClienteAutorizador: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/noauth/por-idCLienteAutorizador/PARAM_ID`,
};

const CreditCard = {
  clientByCard: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/buscar-cliente-cpf-cartao`,
  tokenCreditCard: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/noauth/token-cartao`,
};

const Transaction = {
  insert: `${process.env.REACT_APP_MS_PAGAMENTO}/v1/pagamentos/cartao-credito/teupag`,
  report: `${process.env.REACT_APP_MS_RELATORIO}/v1/relatorios/obter-relatorio-gerencial-transacoes`,
  receipt: `${process.env.REACT_APP_MS_PAGAMENTO}/v1/pagamentos?idTransacao=PARAM_ID`,
};

const Endpoints = {
  authentication: Authentication,
  creditCard: CreditCard,
  transaction: Transaction,
};

export default Endpoints;
