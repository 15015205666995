/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

interface IBullet {
  color?: string;
  size?: number;
  margin?: number;
  marginBottom?: number;
  marginTop?: number;
}

const Bullet = styled.div<IBullet>`
  background-color: ${(p) => (p.color ? p.color : 'transparent')};
  border-radius: 25px;

  height: ${(p) => (p.size ? `${p.size}px` : '18px')};
  width: ${(p) => (p.size ? `${p.size}px` : '20px')};

  margin: ${(p) => (p.margin ? `${p.margin}px` : '0px')};

  margin: ${(p) => (p.margin ? `${p.margin}px` : '0px')};
  margin-bottom: ${(p) =>
    p.marginBottom
      ? `${p.marginBottom}px`
      : p.margin
      ? `${p.margin}px`
      : '0px'};

  margin-top: ${(p) =>
    p.marginTop ? `${p.marginTop}px` : p.margin ? `${p.margin}px` : '0px'};

  min-width: ${(p) => (p.size ? `${p.size}px` : '20px')};
  min-height: ${(p) => (p.size ? `${p.size}px` : '20px')};
  max-height: ${(p) => (p.size ? `${p.size}px` : '20px')};
  max-width: ${(p) => (p.size ? `${p.size}px` : '20px')};
`;

export default Bullet;
