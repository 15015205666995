import React, { memo, useState } from 'react';
import { icons } from '~/assets';
import { useAuth } from '~/hooks/contexts/useAuth';
import { cpfMask, IDefaultReturn } from '~/utils/masks';
import * as St from './styles';
import THEMES from '~/themes/themes';

// const OPTIONS: ITab[] = [
//   {
//     label: 'Cadastro',
//     url: '/register',
//   },
//   {
//     label: 'Consultar/Desativar usuário',
//     url: '/consult/disable',
//   },
// ];

// const NEW_TABS = [
//   ...TABS,
//   ...OPTIONS,
//   {
//     label: 'Sair',
//     onClick: () => console.log('kkk'),
//   },
// ];

const DefaultHeader: React.FC = () => {
  const user = JSON.parse(localStorage.getItem('@NovaEra:user'));
  // const [menuIsOpen, setMenuIsOpen] = useState(false);

  const { signOut } = useAuth();

  // const handleToggleMenu = useCallback(() => {
  //   setMenuIsOpen((prev) => !prev);
  // }, []);

  const handleLogout = () => signOut();

  return (
    <>
      <St.Container>
        <St.Logo src={icons.newLogo} />
        <div
          style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
        >
          <St.WrapperButtons>
            {/* <HeaderSelect label="Área logada" options={OPTIONS} /> */}

            <span>{`Usuário: ${user.cpf} - ${user.nomeTitular}`}</span>
            <St.Logout onClick={handleLogout}>Sair</St.Logout>
          </St.WrapperButtons>
          {/* <St.MenuIcon onClick={handleToggleMenu} /> */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <span
              style={{ fontSize: '0.8rem', color: `${THEMES.colors.primary}` }}
            >
              Versão: &nbsp;
              {process.env.REACT_APP_VERSAO_APP}
            </span>
          </div>
        </div>
      </St.Container>
      {/* <MenuSideBar
        open={menuIsOpen}
        handleClose={handleToggleMenu}
        tabs={NEW_TABS}
      /> */}
    </>
  );
};

export default memo(DefaultHeader);
