import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { icons } from '~/assets';
import Button from '~/components/button';
import Dialog from '~/components/dialog';
import Input from '~/components/input';
import Layout from '~/components/layoutSignIn';
import { useAuth } from '~/hooks/contexts/useAuth';
import Constants from '~/utils/constants';
import * as St from './styles';

const SignIn: React.FC = () => {
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const history = useHistory();
  const { signIn } = useAuth();

  useEffect(() => {
    localStorage.removeItem(Constants.TOKEN_KEY);
    localStorage.removeItem(Constants.TOKEN_BEARER);
  }, []);

  const activeButtonLogin = useMemo(
    () => code.length === 4 && password.length === 6,
    [password, code],
  );

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault();
      setLoading(true);

      try {
        await signIn({ code, password });
        history.push('/home');
      } catch (err) {
        handleToggleDialog();
        setDialogMessage(err.message);
      }
      setLoading(false);
    },
    [history, signIn, code, password],
  );

  const handleToggleDialog = useCallback(() => {
    setDialogIsOpen((prev) => !prev);
  }, []);

  return (
    <Layout>
      <St.Container>
        <St.Logo src={icons.newLogo} alt="Logo TeuCard" />
        <St.Text>Faça seu login para ter acesso</St.Text>
        <St.Form onSubmit={handleSubmit}>
          <Input
            name="code"
            id="code"
            type="text"
            label="Código"
            maxLength={4}
            value={code}
            onChange={(e) => setCode(e.target.value)}
            errorMessage={error}
          />
          <Input
            name="password"
            id="password"
            label="Senha"
            maxLength={6}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            blind
          />
          <Button
            size="large"
            type="submit"
            fullWidth
            disabled={!activeButtonLogin || isLoading}
            active={activeButtonLogin}
            loading={isLoading}
          >
            Entrar
          </Button>
          <St.Version>{process.env.REACT_APP_VERSAO_APP}</St.Version>
        </St.Form>
        <Dialog
          icon={icons.warning}
          positiveLabel="Entendi"
          open={dialogIsOpen}
          message={dialogMessage}
          positiveAction={handleToggleDialog}
        />
      </St.Container>
    </Layout>
  );
};

export default SignIn;
