import { IListColumn } from '~/models/listCol';
import { cpfMask, currencyMask } from '~/utils/masks';

export const HeaderTransactionHistory = [
  {
    id: 1,
    value: 'Data da Transação',
    maxChar: 15,
  },
  {
    id: 2,
    value: 'Estabelecimento',
    maxChar: 10,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Número cartão',
    maxChar: 10,
    textAlign: 'center',
  },

  {
    id: 4,
    value: 'Parcela',
    maxChar: 10,
    textAlign: 'center',
  },
  {
    id: 5,
    value: 'Valor parcela',
    maxChar: 10,
    textAlign: 'center',
  },
  {
    id: 6,
    value: 'Valor da compra',
    maxChar: 12,
    textAlign: 'center',
  },
  {
    id: 7,
    value: 'Comprovante',
    maxChar: 10,
    textAlign: 'center',
  },
];
