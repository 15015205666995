import React from 'react';

import { components } from '~/assets/index';
import * as St from './styles';

interface IButtonSearch {
  onClick: () => void;
}

const ButtonSearch: React.FC<IButtonSearch> = ({ onClick }) => {
  return (
    <St.Container onClick={onClick}>
      <components.Print />
    </St.Container>
  );
};

export default ButtonSearch;
