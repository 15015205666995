import styled from 'styled-components';

import { components } from '~/assets';

export const Container = styled.div`
  position: relative;
  padding: 32px 22px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: ${({ theme: { fontSizes } }) => fontSizes.small};
  font-weight: 600;
`;

export const MenuIcon = styled(components.Menu)`
  display: none;
  position: relative;

  cursor: pointer;
  top: 3px;

  fill: ${({ theme: { colors } }) => colors.primary} !important;
  /* @media (max-width: 700px) {
    display: block;
  } */
`;

export const Logo = styled.img`
  align-self: center;
  width: 131px;
`;

export const Logout = styled.div`
  color: ${({ theme: { colors } }) => colors.primary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const WrapperButtons = styled.div`
  display: flex;

  justify-content: space-between;
  align-items: center;

  ${Logout} {
    margin-left: 40px;
  }

  /* @media (max-width: 700px) {
    display: none;
    width: 0;
  } */
`;

export const WrapperMenu = styled.div`
  /* position: absolute;
  right: 0; */
`;
