import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
}

export const IconRight = styled.img`
  padding-bottom: 8px;
  align-self: flex-end;
  cursor: pointer;
`;

export const ContainerOptions = styled.div<ContainerProps>`
  position: absolute;
  z-index: 50;

  width: 100%;
  height: ${({ isFocused }) => (isFocused ? '100px' : '0px')};
  transition: height 0.6s;

  overflow-y: hidden;
  top: 22px;

  background: #fff;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 1px 7px 0 rgba(201, 201, 201, 0.5);

  > div {
    height: 100%;
    overflow-y: scroll;
    cursor: pointer;

    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      box-shadow: inset 0 0 5px #333;
    }
    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.primary};
      border-radius: 10px;
    }
  }

  .option {
    padding: 10px 10px;

    :hover {
      background: #f1f1f1;
    }
  }
`;

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  width: -webkit-fill-available;
  margin-bottom: 22px;
  color: #4a4b4a;
  font-size: 1rem !important;
  > div {
    border-bottom: 1px solid #afafaf;

    padding-bottom: 10px;

    ${(props) =>
      props.isFocused &&
      css`
        border-bottom: 1px solid ##1e937d;
      `}
  }

  label {
    font-size: 12px;
    color: #757575;
    margin-bottom: 10px;
    ${(props) =>
      props.isFocused &&
      css`
        color: ##1e937d;
      `}
  }
`;

export const ErrorMessage = styled.span`
  position: absolute;
  bottom: -15px;
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
`;

export const Value = styled.div`
  flex: 1;
`;
